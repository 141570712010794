
import "./index.css";
import { Landing } from './pages/Landing';
function App() {

  return (
    <Landing />
  );
}
export default App;
