export const navbar = [
  {
    title: "About",
  },
  {
    title: "Services",
  },
  {
    title: "Projects",
  },
  {
    title: "Resume",
  },
  {
    title: "Contact",
  },
];
